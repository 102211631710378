import { ProfileType } from '../profile'

export interface IInvestOrder {
    UUID: string
    Name: string
    Ticker: string
    Nominal: number
    Price: number
    YieldRate: number
    Volume: number
    Currency: EOrderCurrency
    InvestmentStartDate: string
    InvestmentEndDate: string
    DeliveryDate: string
    RedemptionDate: string
    Skids: IInvestOrderCert[]
    ValidUntil: string
    Transaction?: IInvestOrderTransaction
}

export enum EOrderCurrency {
    RUB = 'RUB',
}

export const OrderCurrencyTranslate = {
    [EOrderCurrency.RUB]: 'Российский рубль',
}

export interface IInvestOrderCert {
    skid: string
    sha1: string
}

export interface IInvestOrderTransaction {
    Hash: string
    json: string
    Rpl: string
    Skid: string
    Sign: string
}

export interface IInvestOrderPreparePayload {
    skid: string
}

export interface IInvestOrderSignPayload {
    sha1: string
}

export interface IInvestOrderSignQueryPayload {
    sign: string
}

export interface ICertificate {
    skid: string
    expired_at: number
    sha1: string
    ogrn?: string
    ogrnip?: string
    snils?: string
    organizationName?: string
    type: ProfileType
    fullName: string
}
